import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ResponsiveDialogComponent } from '../../responsive-dialog/responsive-dialog.component';
import { NewItemFormComponent } from '../form/new-item-form.component';

@Component({
  selector: 'new-item-dialog',
  template: `
    <id-responsive-dialog
      [dialogTitle]="dialogData?.title"
      submitIcon="save"
      cancelText="cancel"
      submitText="save"
      [isSubmitDisabled]="child.newItemForm.invalid"
      (submit)="onSubmit()"
      (cancel)="onCancel()"
    >
      <new-item-form
        #child
        [name]="name"
        [description]="description"
        [saveEvent]="saveSubject"
        (saved)="saved($event)"
      ></new-item-form>
    </id-responsive-dialog>
  `,
  standalone: true,
  imports: [
    NewItemFormComponent,
    ResponsiveDialogComponent,
  ],
})
export class NewItemDialogComponent {
  name = '';
  description = '';

  public saveSubject: Subject<void> = new Subject<void>();

  constructor(
    private _dialogRef: MatDialogRef<NewItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
  ) {
    if (dialogData) {
      this.name = dialogData.name ?? this.name;
      this.description = dialogData.description ?? this.description;
    }
  }

  saved(newItem: any) {
    this._dialogRef.close(newItem);
  }

  onSubmit() {
    this.saveSubject.next();
  }

  onCancel() {
    this._dialogRef.close();
  }
}
