import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'new-item-form',
  templateUrl: './new-item-form.component.html',
  styleUrls: ['./new-item-form.component.scss'],
  standalone: true,
  imports: [NgIf, MatFormFieldModule, MatInputModule, ReactiveFormsModule],
})
export class NewItemFormComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  newItemForm: UntypedFormGroup;

  @Input() name = '';
  @Input() description = '';
  @Input() saveEvent: Observable<void> = of();
  @Output() saved: EventEmitter<any> = new EventEmitter();

  constructor(
    private _fb: UntypedFormBuilder,
  ) {
    this.newItemForm = this._fb.group({
      name: [this.name, Validators.required],
      description: [this.description]
    });
  }

  ngOnInit(): void {
    this.saveEvent
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.saveItem());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  saveItem() {
    this.saved.emit(
      {
        name: this.newItemForm.value.name,
        description: this.newItemForm.value.description
      }
    );
  }

}
