import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'id-responsive-dialog',
  templateUrl: './responsive-dialog.component.html',
  styleUrls: ['./responsive-dialog.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatToolbarModule,
    TranslateModule,
  ]
})
export class ResponsiveDialogComponent {
  @Input() dialogTitle: string
  @Input() submitIcon: string
  @Input() cancelText: string
  @Input() submitText: string
  @Input() showSubmit = true;
  @Input() isSubmitDisabled = false;
  @Output() submit = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  onSubmit() {
    this.submit.emit();
  }

  onCancel() {
    this.cancel.emit();
  }
}
