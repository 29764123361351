<mat-toolbar class="full-mobile-screen">
  <button mat-icon-button (click)="onCancel()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span>{{dialogTitle | translate}}</span>
  <span class="spacer"></span>
  @if (showSubmit) {
  <button mat-icon-button (click)="onSubmit()" [disabled]="isSubmitDisabled">
    <mat-icon>{{submitIcon}}</mat-icon>
  </button>
  }
</mat-toolbar>
<h2 mat-dialog-title class="hide-sm">
  {{dialogTitle | translate}}
</h2>
<mat-dialog-content>
  <ng-content></ng-content>
</mat-dialog-content>
<mat-dialog-actions align="end" class="hide-sm">
  <button mat-button (click)="onCancel()">{{cancelText | translate}}</button>
  @if (showSubmit) {
  <button class="save-button" mat-raised-button color="accent" (click)="onSubmit()" [disabled]="isSubmitDisabled">
    {{submitText | translate}}
  </button>
  }
</mat-dialog-actions>