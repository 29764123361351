<form class="flex-form" [formGroup]="newItemForm">

  <mat-form-field appearance="fill">
    <mat-label>Name</mat-label>
    <input matInput required placeholder="Name" formControlName="name">
    <mat-error *ngIf="newItemForm.controls.name.hasError('required')">
      You must enter a name.
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Description</mat-label>
    <textarea rows="5" matInput placeholder="Description" formControlName="description"></textarea>
  </mat-form-field>

</form>